import React, { useState, useEffect } from 'react';
import './approval.css';
import Sidebar from '../../components/sidebar/Sidebar';
import Topmenu from '../../components/topmenu/Topmenu';
import Widget from '../../components/widget/Widget';
import Breadcrumbs from '../../components/breadcrumb/Breadcrumbs';
import Filter from '../../components/filter/Filter';
import TimePicker from 'react-time-picker';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dropdown from 'react-bootstrap/Dropdown';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Entrypage from '../../components/entrypage/Entrypage';
import Pagination from "../../components/Pagination/Pagination"
import { Link } from 'react-router-dom';
import moment from 'moment/moment'
import axios from "axios";
import Cookies from 'universal-cookie'
import { toast } from 'react-toastify';
import { url } from '../../util';
import { ToastContainer } from 'react-toastify'
const Approval = () => {
    const navigate = useNavigate();
    const [employeeFilter, setEmployeeFilter] = useState({
        employee_query: '',
    })

    const [reason, setReason] = useState('')
    const [ApprovalData, setApprovalData] = useState([])
    
    const cookies = new Cookies();
    const token = cookies.get('fi_token')

    const [total, setTotal] = useState(0)
    const [show, setShow] = useState(false);
    const [approvedSessions, setApprovedSessions] = useState(0)
    const [approvedSessionsNum, setApprovedSessionsNum] = useState(0)
    const [approvedIndividuals, setApprovedIndividuals] = useState(0)
    const [approvedIndividualsNum, setApprovedIndividualsNum] = useState(0)
    const [limit, setLimit] = useState(50)
    const [offset, setOffset] = useState(0)
    const [date, setDate] = useState(new Date())
    const [showData, setShowData] = useState([])
    const [showTime, setShowTime] = useState(null)
    const handleClose = () => setShow(false);
    const [totalLeave, setTotalLeave] = useState(0)
    const [totalLeaveNum, setTotalLeaveNum] = useState(0)
    const [totalOuts, setTotalOuts] = useState(0)
    const handleShow = (data) => {
        setShow(true);


        setShowData(data)

    }
    useEffect(() => {

        if (token === undefined) {
            navigate('/login')
        }
        const headers = { "Authorization": "Bearer " + token }
        let from_date = moment(date)
let to_date = moment(date).add(1, 'd').format("YYYY-MM-DD")
        //   axios.get("http://localhost:9000/api/getStoreIdOfFloorIncharge",{headers}).then((response)=>{
        axios.get(url+"api/getAttendance?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + to_date + "&limit=" + limit + "&offset=" + offset, { headers }).then((responseOne) => {

            axios.get(url+"api/getEmployeesWithTotalOutSessions?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + to_date, { headers }).then((responseTwo) => {
                from_date = moment(date)
                axios.get(url+"api/getTimings?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + to_date, { headers }).then((responseThird) => {
                    responseThird.data.forEach((dataOne) => {
                        responseTwo.data.forEach((dataTwo) => {
                            if (dataOne.employee_id === dataTwo.employee_id) {
                                dataTwo.reason = dataOne.reason
                            }
                        })
                        //   }) 



                        let employeeIds = responseTwo.data.map((data) => data.employee_id)
                        responseOne.data.forEach((data) => {
                            let index = responseTwo.data.findIndex((dataOne) => {
                                console.log(dataOne.employee_id, data.id)
                                return dataOne.employee_id == data.id

                            })

                            if (!employeeIds.includes(data.id)) {
                                data.session = 0


                            }
                            else {

                                data.session = responseTwo.data[index].total_outs
                                data.reason = responseTwo.data[index].reason
                            }
                        })
           
                            setApprovalData(responseOne.data)

                    })
                })
            })
        })
        from_date = moment()
        axios.get(url+"api/getTotalApprovals?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") , { headers }).then((response) => {
            setApprovedSessions(response.data[0].count_id)
            from_date = moment().subtract(1, 'd')
            axios.get(url+"api/getTotalApprovals?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") , { headers }).then((responseOne) => {
                setApprovedSessionsNum(response.data[0].count_id - responseOne.data[0].count_id)
                from_date = moment()
                axios.get(url+"api/getTotalEmployeesApproved?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD"), { headers }).then((responseSecond) => {
                    setApprovedIndividuals(responseSecond.data[0].count_id)
                    from_date = moment().subtract(1, 'd')
                    axios.get(url+"api/getTotalEmployeesApproved?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD"), { headers }).then((responseThird) => {

                        setApprovedIndividualsNum(responseSecond.data[0].count_id - responseThird.data[0].count_id)
                        from_date = moment(date)
                        axios.get(url+"api/getTotal?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") + "&status='On leave'", { headers }).then((response) => {
                            setTotalLeave(response.data[0].count_id)

                            from_date = moment(date).subtract(1, 'd')
                            axios.get(url+"api/getTotal?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") + "&status='On leave'", { headers }).then((responseTwo) => {
                                setTotalLeaveNum(response.data[0].count_id - responseTwo.data[0].count_id)
                            })
                        })
                        let from_date_out = moment()
                        axios.get(url+"api/getTotalOutSessions?from_date=" + from_date_out.format("YYYY-MM-DD") + "&to_date=" + from_date_out.add(1, 'd').format("YYYY-MM-DD"), { headers }).then((responseFourth) => {
                            setTotalOuts(responseFourth.data[0].count_id)

                        })

                    })
                })
            })
        })
       let getString = url+"api/getAttendance?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD")  
        if (employeeFilter.employee_query != '') {
            getString += "&employee_query=" + employeeFilter.employee_query
        }
        axios.get(getString, { headers }).then((responseOne) => {
            setTotal(responseOne.data.length)
        })
    }, [])
    //for popup 
    const selectEntries = (data) => {
        setLimit(data)
    }
    const selectPage = (data) => {
        setOffset((data - 1) * limit)
    }
    useEffect(() => {

        const headers = { "Authorization": "Bearer " + token }
        let from_date = moment(date)
        //   axios.get("http://localhost:9000/api/getStoreIdOfFloorIncharge",{headers}).then((response)=>{
            let to_date = moment(date).add(1, 'd').format("YYYY-MM-DD")
        let getString = url+"api/getAttendance?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + to_date 
        if (employeeFilter.employee_query != '') {
            getString += "&employee_query=" + employeeFilter.employee_query
            getString += "&limit=" + 10 + "&offset=" + 0
        } else {
            getString += "&limit=" + limit + "&offset=" + offset
        }


        axios.get(getString, { headers }).then((responseOne) => {
           
            axios.get(url+"api/getEmployeesWithTotalOutSessions?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + to_date, { headers }).then((responseTwo) => {
                axios.get(url+"api/getTimings?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + to_date, { headers }).then((responseThird) => {
                    responseThird.data.forEach((dataOne) => {
                        responseTwo.data.forEach((dataTwo) => {
                            if (dataOne.employee_id === dataTwo.employee_id) {
                                dataTwo.reason = dataOne.reason
                            }
                        })
                    })



                    let employeeIds = responseTwo.data.map((data) => data.employee_id)
                    responseOne.data.forEach((data) => {
                        let index = responseTwo.data.findIndex((dataOne) => {
                            console.log(dataOne.employee_id, data.id)
                            return dataOne.employee_id == data.id

                        })
                        console.log(index)
                        if (!employeeIds.includes(data.id)) {
                            data.session = 0


                        }
                        else {

                            data.session = responseTwo.data[index].total_outs
                            data.reason = responseTwo.data[index].reason
                        }
                    })
                    setApprovalData(responseOne.data)

                })
            })
            // })
        })
        getString = url+"api/getAttendance?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + to_date 
        if (employeeFilter.employee_query != '') {
            getString += "&employee_query=" + employeeFilter.employee_query
        }
        axios.get(getString, { headers }).then((responseOne) => {
            setTotal(responseOne.data.length)
        })


    }, [employeeFilter]);
    const SubmitApproval = () => {

        const headers = { "Authorization": "Bearer " + token }

        axios.post(url+"api/addTimingyByFI", {
            "employee_id": showData.id,
            "date": moment().format("YYYY-MM-DD"),
            "timer": showTime,
            "approval_status": "Approved",
            "reason": reason
        }, { headers }).then((response) => {
            if (response.status === 200) {
                setShow(false)
                toast.success("Successfully Approved")
            }



        }).catch((err) => {


            toast.error("Employee has already being approved")

        })
    }
    useEffect(() => {

        const headers = { "Authorization": "Bearer " + token }
        let from_date = moment(date)

        //   axios.get("http://localhost:9000/api/getStoreIdOfFloorIncharge",{headers}).then((response)=>{
            let to_date = moment(date).add(1, 'd').format("YYYY-MM-DD")
        axios.get(url+"api/getAttendance?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + to_date + "&limit=" + limit + "&offset=" + offset , { headers }).then((responseOne) => {
            
            axios.get(url+"api/getEmployeesWithTotalOutSessions?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + to_date, { headers }).then((responseTwo) => {
                from_date = moment(date)
                axios.get(url+"api/getTimings?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + to_date, { headers }).then((responseThird) => {
                    responseThird.data.forEach((dataOne) => {
                        responseTwo.data.forEach((dataTwo) => {
                            if (dataOne.employee_id === dataTwo.employee_id) {
                                dataTwo.reason = dataOne.reason
                            }
                        })
                    })



                    let employeeIds = responseTwo.data.map((data) => data.employee_id)
                    responseOne.data.forEach((data) => {
                        let index = responseTwo.data.findIndex((dataOne) => {
                            console.log(dataOne.employee_id, data.id)
                            return dataOne.employee_id == data.id

                        })
                        console.log(index)
                        if (!employeeIds.includes(data.id)) {
                            data.session = 0


                        }
                        else {

                            data.session = responseTwo.data[index].total_outs
                            data.reason = responseTwo.data[index].reason
                        }
                    })
                    setApprovalData(responseOne.data)
                })
            })
            //   })
        })
        axios.get(url+"api/getAttendance?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + to_date , { headers }).then((responseOne) => {
            
            setTotal(responseOne.data.length)
        })
    }, [date, limit, offset])
    
    const changeByEmployee = (data) => {

        // if(data.charAt(0)!=='1')
        //  {

        setEmployeeFilter((prevState) => {
            return { ...prevState, employee_query: data }
        })
    }
    const changeDate = (data) => {
        setDate(data)
    }

    // console.log("ApprovalData", ApprovalData)
    return (
        <>
            <ToastContainer></ToastContainer>
            <div className="home-page">
                <div className="content-side">
                    <Breadcrumbs heading='Approval' activebread='Approval' />
                    <div className="widget">
                        <Widget title='Approved Sessions' value={approvedSessions} num={approvedSessionsNum} duration='Day' />
                        <Widget title='Approved Individuals' value={approvedIndividuals} num={approvedIndividualsNum} duration='Day' />
                        <Widget title='On Leave' value={totalLeave} num={totalLeaveNum} duration='Day' />
                        <Widget title='Out From Store' value={totalOuts} duration='Hour' />
                    </div>

                    <Filter isDate={true} date='Approval Date' data={ApprovalData} changeDate={changeDate} changeByEmployee={changeByEmployee} />
                    <div className="attend-table">
                        <TableContainer component={Paper} className='mb-4'>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='text-start'>Employee Name</TableCell>
                                        <TableCell align="right">EmpID</TableCell>
                                        <TableCell align="right">Session</TableCell>
                                        {/* <TableCell align="right">Reason</TableCell> */}
                                        <TableCell align="right">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ApprovalData.map((val, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">
                                                    <div className="empname d-flex">
                                                        <p>{val.employee_name}</p>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="center">{val.empID}</TableCell>
                                                <TableCell align="center">{val.session}</TableCell>
                                                {/* <TableCell align="center">{val.reason}</TableCell> */}
                                                <TableCell align="center">
                                                    <Dropdown className='table-action'>
                                                        <Dropdown.Toggle> <BiDotsHorizontalRounded />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Link className='dropdown-item' onClick={(e) => handleShow(val)}>
                                                                Approval Break </Link>
                                                            {/* <Link className='dropdown-item' to="">
                                                                        Request for half day action</Link> */}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Pagination selectEntries={selectEntries} selectPage={selectPage} offset={offset} limit={limit} total={total} />

                    </div>
                </div>
            </div>
            {/* break approval popup */}
            <Modal size="md" className='breakpopop'
                aria-labelledby="contained-modal-title-vcenter"
                centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <h4>Break Approval</h4>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="4" xs="4">
                                Name
                            </Form.Label>
                            <Col sm="8" xs="8">
                                <Form.Control plaintext readOnly defaultValue={showData.employee_name} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="4" xs="4">
                                Floor
                            </Form.Label>
                            <Col sm="8" xs="8">
                                <Form.Control plaintext readOnly defaultValue={`${showData.floor_name} (${showData.store_department_name} Section)`} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="4" xs="4">
                                Select Time
                            </Form.Label>
                            <Col sm="8" xs="8">
                                <Form.Group className="mb-3">
                                    <div>
                                        <TimePicker class="form-control" format='HH:mm:ss' onChange={setShowTime} value={showTime} disableClock={true} /></div>
                                </Form.Group>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column sm="4" xs="4">
                                Reason
                            </Form.Label>
                            <Col sm="8" xs="8">
                                <Form.Control as="textarea" value={reason} onChange={(e) => setReason(e.target.value)} rows={3} />
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='cancel' onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button className="send" onClick={SubmitApproval}>
                        Send For Approval
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Approval