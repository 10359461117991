import React from 'react';
import './topmenu.css';
import { BiSearch } from 'react-icons/bi';

import { AiOutlineClockCircle } from 'react-icons/ai';
import { FaRegCalendarAlt } from 'react-icons/fa';
import Notification from '../notification/Notification';

const Topmenu = () => {
    return (
        <>
            <div className="topmenu">
                <div className="flex-box">
                    <form>
                        <input type="text" class="form-control" placeholder="Search" />
                        <BiSearch />
                    </form>
                    <div className="noti">
                        <p><FaRegCalendarAlt style={{fontSize: '17px'}} /> Monday, 16 December 2023</p>
                        <p><AiOutlineClockCircle /> 10:28 PM</p>
                        <Notification />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Topmenu