import React, { useState } from 'react';
import './notification.css';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { RiDashboard2Fill } from 'react-icons/ri';
import { FaSuitcase } from 'react-icons/fa';

const Notification = () => {


    //for popup 
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const Menus = ['Profile', 'Details', 'Settings', 'logout'];

    const [open, setOpen] = useState(false);

    //on window outside click
    // const menuRef = useRef();
    // const imgRef = useRef();

    // window.addEventListener("click", (e) => {
    //     if (e.target !== menuRef.current && e.target !== imgRef.current) {
    //         setOpen(false);
    //     }
    // });

    return (



        <>
            <div className="notification">
                <div className="noti-btn">
                    <IoMdNotificationsOutline
                        // ref={imgRef}
                        onClick={() => setOpen(!open)} />
                </div>
                {open && (
                    <div
                        // ref={menuRef}
                        className="dropmenu" >
                        <ul>
                            {/* {
                                Menus.map((menu) => (
                                    <li onClick={() => setOpen(false)}
                                        key={menu}><Link to='' onClick={handleShow}>{menu}</Link></li>
                                ))
                            } */}
                            <li onClick={() => setOpen(false)}><span><FaSuitcase /></span> <p>Don’t Forget To Grade Your Employee Every Week</p> <Link onClick={handleShow}>View</Link></li>
                            <li onClick={() => setOpen(false)}><span><FaSuitcase /></span> <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> <Link onClick={handleShow}>View</Link></li>
                            <li onClick={() => setOpen(false)}><span><FaSuitcase /></span> <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> <Link onClick={handleShow}>View</Link></li>
                            
                            <li></li>
                        </ul>
                    </div>
                )}
            </div>

            {/* break approval popup */}
            <Modal size="md" className='breakpopop'
                aria-labelledby="contained-modal-title-vcenter"
                centered show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <h5 className='pt-4'>Don’t Forget To Grade Your Employee Every Week</h5>
                </Modal.Header>
                <Modal.Body>
                    <p style={{ color: '#858282', fontSize: '14px' }}>Corem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tempus urna at turpis condimentum lobortis.</p>
                    <Button className='cancel' onClick={handleClose}
                        style={{ float: 'right', background: '#96503F', borderColor: '#96503F', padding: '10px 50px' }}>
                        Ok
                    </Button>
                </Modal.Body>
            </Modal>
        </>


    )
}

export default Notification