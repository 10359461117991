import classes from './MainTable.module.css'
import { useState } from 'react'

import moment from 'moment'
import axios from "axios";
import { url } from '../../util';
const MainTable = (props) => {
    const [rows, setRows] = useState(5)

    const tableHeadings = props.headings
    const tableData = props.data

    const newData = tableData.slice(0, rows)
    console.log(newData);

    const clickHandler = (element) => {
        props.func([true, element])
    }
    
    
const inEmployee=(element)=>{
    console.log("hello")
    const token=localStorage.getItem('token')
    const headers={"Authorization":"Bearer "+token}
    let in_time=moment()
    let time_diff=in_time.diff(moment(element.out_time,'hh:mm:ss'),'s')
    console.log(time_diff)
    function pad(num) {
        return ("0"+num).slice(-2);
    }
    function hhmmss(secs) {
      var minutes = Math.floor(secs / 60);
      secs = secs%60;
      var hours = Math.floor(minutes/60)
      minutes = minutes%60;
      return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
      // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
    }

    let timer=hhmmss(time_diff)
    axios.patch(url+"api/updateTiming/"+element.timing_id,{
        "in_time":in_time.format("HH:mm:ss"),
        "timer":timer
    },{headers}).then(()=>{
        window.location.reload(false)
    })
}
    // Btn2 Handler

    return (
        <div className={classes.table_container}>
            <table className={classes.table}>
                <thead>
                    <tr>
                        {/* Table Headings */}
                        {tableHeadings.map((element, index) => (
                            <th key={index}>{element.heading}</th>
                        ))}
                        {props.Btn === true ?
                            <th style={{ textAlign: "center" }}>Action</th>
                            : null}
                            {props.Btn2 === true ?
                            <th style={{ textAlign: "center" }}>Action</th>
                            : null}
                             {props.btnView === true ?
                            <th style={{ textAlign: "center" }}>Action</th>
                            : null}
                    </tr>
                </thead>
                <tbody>

                    {/* {Data.map(val)=>(
                        <div>{val.name}</div>
                    )} */}

 
                   {newData&&newData.map((val, index) => (
                    
                        <tr key={index}>
                            {props.keys.map((element, index) => (
                                <td  key={index} className={element === 'employee_name' ? classes.name_feild : ''}>
                                
                                    {/* { element === 'employee_name' ? <img src={url+val.photo} alt="" /> : ''} */}
                                    {val[`${element}`]}
                                </td>
                            ))}
                            {
                                props.Btn === true ? <td><button   onMouseDown={(e) => { val.status==='Out'?inEmployee(val):clickHandler(val)   }}>{val.status==='Out'?'In':'Out'}</button></td> : null
                            }
                            {
                                props.btnView === true ? <td><button   onMouseDown={(e) => { props.func2(val.empID) }}>View</button></td> : null
                            }
                            {
                                props.Btn2 === true? <td><button onClick={() => { inEmployee(val) }}>In</button></td> :null
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
            <input className={props.Inp === false? classes.none : classes.table_input}  type="number" value={rows} min={5} max={tableData.length} onChange={(e)=>{e.target.value < 5 ? setRows(5) :setRows(e.target.value)}} />
        </div>
     )
}

export default MainTable