import React,{useState} from 'react';
import './attendance.css';
import Sidebar from '../../components/sidebar/Sidebar';
import Topmenu from '../../components/topmenu/Topmenu';
import Widget from '../../components/widget/Widget';
import Breadcrumbs from '../../components/breadcrumb/Breadcrumbs';
import Filter from '../../components/filter/Filter';
import { AttendanceData } from './AttendanceData';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Entrypage from '../../components/entrypage/Entrypage';

const AttendanceCorrect = () => {
    
    const [date,setDate]=useState(new Date())
    const changeDate=(data)=>{
        setDate(data)
    }
    return (
        <>
            <div className="home-page">
                <div className="row">
                    <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                        <div className="sidebar-page">
                            <Sidebar />
                        </div>
                    </div>
                    <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
                        <Topmenu />
                        <div className="content-side">
                            <Breadcrumbs heading='Correction' activebread='Correction' />
                            <div className="widget">
                                <Widget title='Corrections' value={45} num={15} duration='Day' />
                                <Widget title='Pending Corrections' value={5} num={14} duration='Day' />
                                <Widget title='On Leave' value={3} num={15} duration='Day' />
                                <Widget title='Out From Store' value={45} num={15} duration='Hour' />
                            </div>
                            <Filter date='Attendance Date' changeDate={changeDate} />
                            <div className="attend-table">
                                <TableContainer component={Paper} className='mb-4'>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className='text-start'>Employee Name</TableCell>
                                                <TableCell align="right">EmpID</TableCell>
                                                <TableCell align="right">Time</TableCell>
                                                <TableCell align="right">Attendance</TableCell>
                                                <TableCell align="right">Send for Correction</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {AttendanceData.map((val, index) => {
                                                return (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <div className="empname d-flex">
                                                                <img src={val.imgsrc} alt='clients' />
                                                                <p>{val.name}</p>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="center">{val.empid}</TableCell>
                                                        <TableCell align="center">{val.time}</TableCell>
                                                        <TableCell align="center"><Link className={val.cName}>{val.status}</Link></TableCell>
                                                        <TableCell align="center"><Link to='/approval'>{val.approval}</Link></TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <Entrypage />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AttendanceCorrect