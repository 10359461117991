import React, { useState, useEffect } from 'react';
import './timing.css';
import Sidebar from '../../components/sidebar/Sidebar';
import Topmenu from '../../components/topmenu/Topmenu';
import Widget from '../../components/widget/Widget';
import Breadcrumbs from '../../components/breadcrumb/Breadcrumbs';
import Filter from '../../components/filter/Filter';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dropdown from 'react-bootstrap/Dropdown';
import { BsShop } from 'react-icons/bs';
import Entrypage from '../../components/entrypage/Entrypage';
import Pagination from '../../components/Pagination/Pagination';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie'
import moment from 'moment/moment'
import axios from "axios";
import { url } from '../../util';
const Timing = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [pending, setPending] = useState(0)
    const [pendingNum, setPendingNum] = useState(0)
    const [absent, setAbsent] = useState(0)
    const [absentNum, setAbsentNum] = useState(0)
    const [approved, setApproved] = useState(0)
    const [approvedNum, setApprovedNum] = useState(0)
    const navigate = useNavigate();
    const [employeeFilter, setEmployeeFilter] = useState({
        employee_query: '',
    })
    const [total, setTotal] = useState(0)
    const [totalOuts, setTotalOuts] = useState(0)
    const [limit, setLimit] = useState(50)
    const [offset, setOffset] = useState(0)
    const [AttendanceData, setAttendanceData] = useState([])
    const cookies = new Cookies();
    const token = cookies.get('fi_token')

    useEffect(() => {

        if (token === null) {
            navigate('/login')
        }
        const headers = { "Authorization": "Bearer " + token }
        let from_date = moment()
        axios.get(url+"api/getAttendance?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") + "&limit=" + limit + "&offset=" + offset + "&status='Absent'&status='Pending'", { headers }).then((response) => {
            setAttendanceData(response.data)
        })
        from_date = moment()
        axios.get(url+"api/getAttendance?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") + "&status='Absent'&status='Pending'", { headers }).then((response) => {
            setTotal(response.data.length)
        })
        from_date = moment()
        axios.get(url+"api/getTotal?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") + "&status='Pending'", { headers }).then((response) => {
            setPending(response.data[0].count_id)
            from_date = moment().subtract(1, 'd')
            axios.get(url+"api/getTotal?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") + "&status='Pending'", { headers }).then((responseOne) => {

                setPendingNum(response.data[0].count_id - responseOne.data[0].count_id)
                from_date = moment()
                axios.get(url+"api/getTotal?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") + "&status='Absent'", { headers }).then((responseSecond) => {
                    setAbsent(responseSecond.data[0].count_id)
                    from_date = moment().subtract(1, 'd')
                    axios.get(url+"api/getTotal?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") + "&status='Absent'", { headers }).then((responseThird) => {

                        setAbsentNum(responseSecond.data[0].count_id - responseThird.data[0].count_id)
                        from_date = moment()
                        axios.get(url+"api/getTotalApproved?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") + "&status='Approved'", { headers }).then((responseFourth) => {
                            setApproved(responseFourth.data[0].count_id)
                            from_date = moment().subtract(1, 'd')
                            axios.get(url+"api/getTotalApproved?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") + "&status='Approved'", { headers }).then((responseFifth) => {

                                setApprovedNum(responseFourth.data[0].count_id - responseFifth.data[0].count_id)
                                let from_date_out = moment()
                                axios.get(url+"api/getTotalOutSessions?from_date=" + from_date_out.format("YYYY-MM-DD") + "&to_date=" + from_date_out.add(1, 'd').format("YYYY-MM-DD"), { headers }).then((responseSixth) => {
                                    setTotalOuts(responseSixth.data[0].count_id)

                                })

                            })
                        })
                    })

                })
            })

        })

    }, [])
    useEffect(() => {

        let from_date = moment(startDate)
        const headers = { "Authorization": "Bearer " + token }

        let getString = url+"api/getAttendance?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") + "&status='Absent'&status='Pending'"
        if (employeeFilter.employee_query != '') {
            getString += "&employee_query=" + employeeFilter.employee_query
            getString += "&limit=" + 10 + "&offset=" + 0
        } else {
            getString += "&limit=" + limit + "&offset=" + offset
        }
        axios.get(getString, { headers }).then((response) => {

            setAttendanceData(response.data)
        })
        from_date = moment(startDate)
        getString = url+"api/getAttendance?from_date=" + from_date.format("YYYY-MM-DD") + "&to_date=" + from_date.add(1, 'd').format("YYYY-MM-DD") + "&status='Absent'&status='Pending'"
        if (employeeFilter.employee_query != '') {
            getString += "&employee_query=" + employeeFilter.employee_query

        }
        axios.get(getString, { headers }).then((response) => {

            setTotal(response.data.length)
        })


    }, [employeeFilter, startDate, limit, offset])
    const changeByEmployee = (data) => {

        // if(data.charAt(0)!=='1')
        //  {

        setEmployeeFilter((prevState) => {
            return { ...prevState, employee_query: data }
        })
    }
    const changeDate = (data) => {
        setStartDate(data)
    }
    const selectEntries = (data) => {
        setLimit(data)
    }
    const selectPage = (data) => {
        setOffset((data - 1) * limit)
    }
    return (
        <>
            <div className="home-page">
                        <div className="content-side">
                            <Breadcrumbs heading='Timing' activebread='Timing' />
                            <div className="widget">
                                <Widget title='Pending Attendance' value={pending} num={pendingNum} duration='Day' />
                                <Widget title='Absent' value={absent} num={absentNum} duration='Day' />
                                <Widget title='Approved Timings' value={approved} num={approvedNum} duration='Day' />
                                <Widget title='Out From Store' value={totalOuts} duration='Hour' />
                            </div>
                            {/* <div className="floor-store d-flex">
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <BsShop /> Floor
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#">Floor 1</Dropdown.Item>
                                        <Dropdown.Item href="#">Floor 2 </Dropdown.Item>
                                        <Dropdown.Item href="#">Floor 3 </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        <BsShop /> Store 1
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#">Store 2</Dropdown.Item>
                                        <Dropdown.Item href="#">Store 3 </Dropdown.Item>
                                        <Dropdown.Item href="#">Store 4 </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div> */}
                            <Filter isDate={true} date='Date' data={AttendanceData} changeDate={changeDate} changeByEmployee={changeByEmployee} />
                            <div className="attend-table">
                                <TableContainer component={Paper} className='mb-4'>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className='text-start'>Employee Name</TableCell>
                                                <TableCell align="right">EmpID</TableCell>
                                                <TableCell align="right">Time</TableCell>
                                                <TableCell align="right">Attendance</TableCell>
                                                <TableCell align="right">Send for Approval</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {AttendanceData.map((val, index) => {
                                                return (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <div className="empname d-flex">
                                                                {/* <img src={val.imgsrc} alt='clients' /> */}
                                                                <p>{val.employee_name}</p>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="center">{val.empID}</TableCell>
                                                        <TableCell align="center">{val.datetime.split(" ")[1].substring(0, 8)}</TableCell>
                                                        <TableCell align="center"><Link className={val.cName}>{val.status}</Link></TableCell>
                                                        <TableCell align="center"><Link to={`/timing/approval/${val.employee_id}/${val.attendance_id}/${val.datetime.split(" ")[0]}`}>{val.status !== 'Pending' ? 'Send For Approval' : null}</Link></TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <Pagination selectEntries={selectEntries} selectPage={selectPage} offset={offset} limit={limit} total={total} />

                            </div>
                        </div>
                    </div>
        </>
    )
}

export default Timing