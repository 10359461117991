import { useNavigate } from 'react-router-dom'
import './BottomButtonContainer.css'

const BottomButtonContainer = (props) => {

  const navigate = useNavigate();

  const backHandler = () => {
    return navigate(-1)
  }

  return (
    <div className={'btn_container'}>

      <button type='button' className={'cancel'} onClick={props.func === true ? () => props.cancelRequests() : backHandler}>{props.cancel}</button>
      {props.func2 && <button className={'accept'} onClick={props.func && props.func === true ? props.func2 : ''}>{props.approve}</button>}
      {props.f1 && <button type={props.f1 == true ? 'submit' : 'button'} className={'accept'}>{props.approve}</button>}
    </div>
  )
}

export default BottomButtonContainer